const config = {
  rootProjectId: "root",
  uiBucketName: "b2b.c.retter.io",
  appUrl: "https://api.b2b.retter.io/",
  cosUrl: "api.b2b.retter.io",
  version: "2.1.0",
  captchaKey: "6LdvvfYpAAAAAIhT6yoDR-0MoTc7XYJMf9O3hxfk",
  stage: "PROD"
}
export default config

